.App {
  text-align: center;
}

a {
  background-color: #0a99db;
  color:white;
  padding: 10px;
  text-align: center;  
  text-decoration: none;
  border-radius: 8px;
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: inline-block;
  margin: 0 auto;
}
a:hover {
  background-color:#1da5e4;
}

.App-logo {
  text-align: center;
}
.App-logo:hover {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce;
}

@keyframes bounce {
  50% { transform: translateY(15px); }
}
